<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/jishu/ecu_banner'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.jpg')"></el-image>
        </div>
        <div class="appmain">
            <div style="margin-top:5%">
                <el-form ref="form" :model="ecuParams"  label-width="30px">
                    <el-row>
                        <el-col :span="12" :xs="24">
                            <el-form-item label="SPN" prop="spn">
                                <el-input v-model.trim="ecuParams.spn"  @keyup.enter.native="search" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" :xs="24">
                            <el-form-item label="FMI" prop="fmi" style="margin-left:20px">
                                <el-input v-model.trim="ecuParams.fmi" @keyup.enter.native="search">
                                    <el-button slot="append" style="background: #0052D9;color:#ffffff;border-radius:0" @click="search">{{$t('inquire')}}</el-button>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="list" v-if="list !==undefined && list != null  && list.length > 0">
                <!-- <div class="tab">
                    <div class="tabitem active">{{$t('ecu.brand')}}</div>
                    <div class="tabitem">SPN</div>
                    <div class="tabitem">FMI</div>
                </div> -->
                <div class="tablist">
                    <el-collapse :accordion='true'>
                        <el-collapse-item v-for="(item,index) in list" :key="index" :title="'SPN:'+item.spn+' FMI:'+item.fmi+' '+item.title+'-'+item.company" :name="item.id">
                            <div>
                                <div>{{$t('ecu.failureCause')}}：</div>
                                <div v-html="item.ques"></div>
                            </div>
                            <div v-if="item.reaction">
                                <div>{{$t('ecu.reactionTitle')}}：</div>
                                <div v-html="item.reaction"></div>
                            </div>
                            <div v-if="item.ans">
                                <div>{{$t('ecu.ansTitle')}}：</div>
                                <div v-html="item.ans"></div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
            <div v-if="list == undefined ||list == null || list.length <= 0">
                <div class="empty">
                    <el-image :src="require('@/assets/jishu/empty.png')"></el-image>
                </div>
                <div class="emptytitle">{{$t('notAvailable')}}</div>
                <div class="emptytitle">{{$t('nosousuo')}},<span style="color:#0052D9;cursor: pointer;" @click="nav('feedback')">{{$t('BUG')}}</span></div>
            </div>
            <el-pagination
                v-show="total>10"
                style="margin-top:3%;text-align:center"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="ecuParams.page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="ecuParams.limit"
                layout="total, prev, pager, next"
                :pager-count="5"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { ecuList} from "@/api/api"; 
export default {
    data(){
        return{
            list:[],
            total:0,
            ecuParams:{
                page:1,
                limit:10
            },
            title:''
        }
    },
    created(){
        this.title = this.$t('layout.ECUfaultcode')+'-'+this.$t('seo.title')
        this.getList()
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.keywords')
            },
            {
                name: 'description',
                content: this.$t('seo.description')
            }
        ]
        }
    },
    methods:{
        async getList(){
            const{data:res} = await ecuList(this.ecuParams)
            this.total = res.total
            this.list = res.list
        },
        search(){
            this.ecuParams.page = 1
            this.getList()
        },
        handleSizeChange(value){
            this.ecuParams.limit = value
            this.getList()
        },
        handleCurrentChange(value){
            this.ecuParams.page = value
            this.getList()
        },
        nav(url){
            this.$router.push('/'+url,'_blank')
        }
    }
}
</script>
<style lang="scss" scoped>
    .appmain{
        width: 60%;
    }
    @media screen and (max-width: 480px) {
        .appmain{
            width: 100%;
        }
    }
    .empty{
        width: 100px;
        margin: 0 auto;
        margin-top: 8%;
    }
    .emptytitle{
        text-align: center;
        margin-top: 10px;
    }
    .tab{
        display: flex;
        .tabitem{
            width: 205px;
            text-align: center;
            background: #E6E6E6;
            height: 46px;
            line-height: 46px;
            cursor: pointer;
            font-family: SourceHanSansCN-Normal;
            font-weight: 400;
            font-size: 16px;
        }
        .active{
            color: #0052D9;
            background: #fff;
        }
    }
    .tablist{
        width: 100%;
        background: #fff;
        padding-left: 5%;
    }
</style>
<style scoped>
    @media screen and (max-width: 480px) {
        .tablist >>> .el-collapse-item__header{
            height: 150px !important;
        }
    }
</style>
<style scoped>
    .tablist >>> .el-collapse-item__header{
        font-size: 18px;
        color: #333333;
        height: 70px;
        font-weight: bold;
    }
    .tablist >>> .el-collapse-item__content{
        padding-top: 3%;
        font-size: 17px;
        color: #333333;
        padding-left: 50px;
    }
    /deep/ .el-form-item__content{
        margin-left: 50px !important;;
    }
    .tablist >>> .el-collapse-item__wrap{
        background: #F6F6F6;
        margin-right: 5%;
    }
</style>